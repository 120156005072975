import { GetStaticProps } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import ArrowLeftIcon from 'public/icons/arrow-left-line.svg';
import { useAuth } from 'react-oidc-context';
import { Button } from '@/components/Button';
import Typography from '@/components/Typography';
import { Outer, Wrapper } from '@/domains/404/404.styles';
import { urls } from '@/utils/urls';
const Styled404LottieAnimation = dynamic(() => import('@/domains/404/StyledLottie404Animation'), {
  ssr: false
});
const NotFound = () => {
  const {
    isAuthenticated
  } = useAuth();
  const {
    t
  } = useTranslation('common');
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="404.tsx">
        <title>{t('title.errorPage404')}</title>
      </Head>
      <Outer data-sentry-element="Outer" data-sentry-source-file="404.tsx">
        <Wrapper data-sentry-element="Wrapper" data-sentry-source-file="404.tsx">
          <Styled404LottieAnimation play loop data-sentry-element="Styled404LottieAnimation" data-sentry-source-file="404.tsx" />
          <Typography element="h2" size="sm" align="center" data-sentry-element="Typography" data-sentry-source-file="404.tsx">
            {t('404page.title')}
          </Typography>
          <Button href={isAuthenticated ? urls.exchanger : urls.landing} leftComponent={<ArrowLeftIcon />} data-sentry-element="Button" data-sentry-source-file="404.tsx">
            {t('404page.button')}
          </Button>
        </Wrapper>
      </Outer>
    </>;
};
export default NotFound;
export const getStaticProps: GetStaticProps = async ({
  locale
}) => {
  return {
    props: {
      ...(locale ? await serverSideTranslations(locale, ['common', 'footer', 'error', 'header', 'notifications', 'instantSell', 'payments']) : {})
    }
  };
};