import { PropsWithChildren, ReactNode } from 'react';
import { StyledTypography, TextAlign, TypograghySize } from './Typography.styles';
export interface TypographyInterface {
  size?: TypograghySize;
  className?: string;
  element?: 'h1' | 'h2' | 'h3' | 'p';
  align?: TextAlign;
}
export const Typography = ({
  size = 'lg',
  children,
  className,
  element = 'p',
  align
}: PropsWithChildren<TypographyInterface>) => {
  return <StyledTypography className={className} size={size} as={element} align={align} data-sentry-element="StyledTypography" data-sentry-component="Typography" data-sentry-source-file="Typography.tsx">
      {children}
    </StyledTypography>;
};
export const TypographyHeader = ({
  children
}: {
  children: ReactNode;
}) => <Typography size="xl" element="h1" data-sentry-element="Typography" data-sentry-component="TypographyHeader" data-sentry-source-file="Typography.tsx">
    {children}
  </Typography>;