import styled from 'styled-components'

import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { GOLD, WHITE } from '@/styles/colors'
import { goldman } from '@/utils/fonts'

export const TypographySizes = {
  sm: '24px',
  lg: '48px',
  xl: '60px',
  xxl: '100px',
}

export const MobileTypographySizes = {
  sm: '16px',
  lg: '20px',
  xl: '24px',
  xxl: '48px',
}

export type TextAlign = 'left' | 'center' | 'right'

export type TypograghySize = keyof typeof TypographySizes

export const StyledTypography = styled.p<{
  size: TypograghySize
  align?: TextAlign
}>`
  font-family: ${goldman.style.fontFamily};
  text-transform: uppercase;
  font-weight: 700;
  text-align: ${({ align }) => align || 'initial'};
  margin-top: 16px;
  margin-bottom: 16px;
  color: ${WHITE};
  font-size: ${({ size }) =>
    size ? MobileTypographySizes[size] : MobileTypographySizes.lg};

  span {
    color: ${GOLD.x200};
  }

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    margin-top: 32px;
    margin-bottom: 32px;
    font-size: ${({ size }) =>
      size ? TypographySizes[size] : TypographySizes.lg};
  }
`
